import { useOptimizelyFeatureFlag } from '@/libs/v2/contexts/optimizely';

type Search1bVariables = {
  redirectFromHomePage: boolean;
  redirectFromDestinationPage: boolean;
};

const DEFAULT_VARIABLES: Search1bVariables = {
  redirectFromHomePage: false,
  redirectFromDestinationPage: false,
};

export const useSearch1b = (): Search1bVariables => {
  const [decision] = useOptimizelyFeatureFlag('qantas-holidays-search-1b');

  return {
    ...DEFAULT_VARIABLES,
    ...decision.variables,
  };
};
