type GetSearchPageUrlParams = {
  destination: string;
  originCode: string;
  departureDate: string;
  returnDate: string;
  adults: string;
  children: string;
  infants: string;
};

const getOldSearchPageUrlObject = ({
  destination,
  ...restParams
}: GetSearchPageUrlParams) => {
  return {
    pathname: `/search/${destination}`,
    query: {
      ...restParams,
    },
  };
};

const getSearch1bPageUrlObject = (params: GetSearchPageUrlParams) => {
  return {
    pathname: `/search/list`,
    query: params,
  };
};

export const getSearchPageUrl = (
  params: GetSearchPageUrlParams,
  redirectToSearch1b: boolean,
) => {
  if (redirectToSearch1b) {
    return getSearch1bPageUrlObject(params);
  }

  return getOldSearchPageUrlObject(params);
};
